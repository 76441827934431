@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-BoldCnIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-BoldCn.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro';
  src: url('AvenirNextLTPro-BoldIt.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro Cn';
  src: url('AvenirNextLTPro-CnIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Cn.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro Cn';
  src: url('AvenirNextLTPro-DemiCn.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Demi.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-DemiCnIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro';
  src: url('AvenirNextLTPro-DemiIt.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-HeavyCn.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro';
  src: url('AvenirNextLTPro-HeavyIt.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-HeavyCnIt.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-It.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-MediumCn.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro MediumCn';
  src: url('AvenirNextLTPro-MediumCnIt.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-MediumIt.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-UltLt.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('AvenirNextLTPro-UltLtCn.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro UltLight';
  src: url('AvenirNextLTPro-UltLtIt.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext LT Pro UltLightCn';
  src: url('AvenirNextLTPro-UltLtCnIt.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
