@import 'variables';

.profile_main {
  .title {
    font-weight: 600;
    color: $dark;
  }

  .nav-pills {
    background-color: $white-primary;
    border-radius: 6px;
    min-width: 180px;

    .nav-link {
      border-radius: 6px;
      color: $primary;

      &.active {
        color: #fff;
        background-color: $primary;
      }

      @media only screen and (max-width: 767px) {
        & {
          width: 100%;
        }
      }

      @media only screen and (min-width: 768px) {
        & {
          padding-block: 15px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      & {
        width: 100%;
      }
    }
  }

  .btn-back {
    position: absolute;
    inset-inline-start: 12px;
    width: fit-content;
    background-color: $gray;
    color: $dark;
    padding-block: 12px;
    padding-inline: 16px;
    border-radius: $border-radius;
  }
}
