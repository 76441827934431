@import 'variables';

.add_btn {
  position: fixed;
  bottom: 20px;
  inset-inline-end: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.21));
}
