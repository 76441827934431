@import 'variables';

.main {
  text-align: center;

  .welcome {
    color: $dark-blue;
  }

  .title {
    color: $dark;
    font-weight: bold;
  }
}
