@import 'variables';

.main {
  .title {
    font-weight: 600;
    color: $dark;
  }

  .note {
    color: $light-primary;
  }

  .fixed_bottom {
    position: sticky;
    inset-inline-start: 0;
    inset-block-end: 8px;
    width: 100%;

    .total_card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 20px;
      border-radius: $border-radius;
      box-shadow: 0px -5px 39px 0px rgba(0, 0, 0, 0.16);

      .items {
        font-weight: bold;
      }

      .total {
        font-size: 14px;
        margin-inline-end: 8px;
      }

      .price_container {
        .currency {
          font-size: 18px;
          color: $dark;
          font-weight: bold;
        }

        .price {
          font-size: 26px;
          font-weight: bold;
          color: $primary;
        }
      }
    }
  }
}
