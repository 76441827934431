@import 'variables';

.file_control_container {
  text-align: start;

  .label {
    color: $blue;
    font-size: 12px;
  }

  .file_control {
    position: relative;
    border: 2px dashed $border-color;
    background-color: $gray;
    padding: 1rem;
    cursor: pointer;
    min-height: 120px;

    .file_input {
      display: none;
    }

    .file_list {
      margin-block: 1rem;

      .file_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: $border-color;
        border-radius: $border-radius;
        font-weight: bold;
        padding: 8px;
        font-size: 18px;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    .upload_icon_container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        color: #494949;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
