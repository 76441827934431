@import 'variables';

.transfer_main {
  .title {
    font-weight: 600;
    color: $dark;
  }

  .qrcode_container {
    width: fit-content;
    background-color: #fff;
    border-radius: $border-radius;
    padding-block: 40px;
    padding-inline: 48px;
    box-shadow: 0px 4px 84px 0px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 425px) {
      width: 100%;
      padding: 32px;
      text-align: center;
    }
  }

  .btn_back {
    position: absolute;
    inset-inline-start: 12px;
    width: fit-content;
    background-color: $gray;
    color: $dark;
    padding-block: 12px;
    padding-inline: 16px;
    border-radius: $border-radius;
  }
}
