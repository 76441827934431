@import 'variables';

.form_control {
  display: block;
  width: 56px;
  height: 54px;
  margin-right: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
  min-width: 0;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  outline: none;
  background-clip: padding-box;
  background-color: #f7f7f7;

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
  }
}
