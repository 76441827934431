@import 'variables';

.selectable_tag {
  width: fit-content;
  padding-block: 10px;
  padding-inline: 20px;
  border-radius: 53px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  cursor: pointer;

  .title {
    font-size: 14px;
  }

  &.selected {
    color: #fff;
    background-color: $primary;
  }
}
