@import 'variables';

.selectable_box {
  height: 100px;
  border-radius: $border-radius;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  cursor: pointer;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  &.selected {
    color: #fff;
    background-color: $primary;
  }

  &.selected:not(.unselectable) {
    cursor: unset;
  }
}
