.reports_container {
  .personal_report_container {
    margin-bottom: 36px;
  }

  .family_report_container {
    margin-bottom: 64px;
  }

  .report_title {
    font-weight: bold;
  }
}
