@import 'variables';

.modal {
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;

  .modal_content {
    border-radius: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    outline: 0;

    .modal_header {
      justify-content: center;
      border-block-end: 0;
      padding-block: 12px;
      padding-inline: 32px;

      .modal_title {
        color: $primary;
        font-weight: bold;
      }
    }

    .modal_body {
      position: relative;
      flex: 1 1 auto;
      padding: var(--bs-modal-padding);
      color: #000;
      font-size: 20px;
    }

    .modal_footer {
      border-block-start: 0;
      padding-block: 12px;
      padding-inline: 32px;
      display: flex;
      gap: 16px;
    }
  }

  &.fade {
    opacity: 0;
    transition: opacity 0.2s linear;

    &.fade_in {
      opacity: 1;
    }

    &.fade_out {
      opacity: 0;
    }

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }
}
