@import 'variables';

.checkbox_container {
  display: inline-flex;
  align-items: center;
  margin-block-end: 0.125rem;

  .checkbox {
    position: relative;
    width: 40px;
    height: 22px;
    appearance: none;
    -webkit-appearance: none;
    background: $gray-300;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.35s;

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      inset-block-start: 50%;
      inset-inline-start: 0;
      background: $gray-500;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      transform: scale(1.3) translateY(-38%);
      transition: 0.35s;
    }

    &:checked {
      background: $light-primary;

      &:before {
        inset-inline-start: 20px;
        background: $primary;
      }
    }
  }

  .label {
    display: inline-block;
    cursor: pointer;
    margin-inline-start: 0.8rem;
  }
}
