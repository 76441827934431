// Color Variables
$primary: #a20101;
$light-primary: #fb5858;
$white-primary: #f6e6e6;

$dark: #1c1b29;

$blue: #3878e2;
$light-blue: rgba(56, 120, 226, 0.08);
$dark-blue: #1f1e2e;

$gray: #f2f2f2;
$gray-100: #ededed;
$gray-200: #e5e7eb;
$gray-300: #d2d6dc;
$gray-400: #9fa6b2;
$gray-500: #6b7280;

// Border Variables
$border-color: #d9d9d9;
$border-radius: 10px;
