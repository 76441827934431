@import 'variables';

.form_control {
  .label {
    width: 100%;
    position: relative;
    display: inline-block;

    .span {
      padding: 10px;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 4px;
      transition: 0.2s;
      transition-timing-function: ease;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 0.5;
      color: #000;
    }

    .input {
      padding: 14px;
      width: 100%;
      background-color: #fff;
      border-radius: $border-radius;
      border: 1px solid $border-color;
      outline: none;
      background-clip: padding-box;

      &:focus {
        border-color: $blue;
      }

      &:focus + .span,
      &:not(:placeholder-shown) + .span {
        height: 0;
        top: 0;
        left: 20px;
        opacity: 1;
        transform: translateY(-91%) translateX(0px);
        background-color: #fff;
        color: $blue;
        font-size: 12px;
      }

      &:focus + .span,
      &:not(:-ms-input-placeholder) + .span {
        opacity: 1;
        transform: scale(0.75) translateY(-70%) translateX(0px);
      }

      &[type='search'] {
        padding-inline-end: 60px;
      }

      &[type='search']::-webkit-search-cancel-button {
        position: absolute;
        inset-inline-end: 42px;
      }

      &[type='search'] + .span + .search_icon {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 16px;
        transform: translateY(-50%);
        pointer-events: none;
      }

      &[type='url'] {
        padding-inline-end: 100px;
      }

      &[type='url'] + span + .copy_link {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 16px;
        transform: translateY(-50%);
        color: $gray-500;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .error_message {
    display: none;
    color: $light-primary;
  }

  &.danger {
    .label {
      .input {
        border-color: $light-primary;
      }
    }

    .error_message {
      display: flex;
      align-items: center;
      margin-top: 6px;

      img {
        margin-inline-end: 8px;
      }
    }
  }
}
