@import 'variables';

.btn {
  border-radius: 15px;
  font-weight: bold;
  font-size: 18px;
  padding: 16px;
  line-height: 24.59px;

  &.btn_primary {
    background-color: $primary;
    border: 0;
    color: #fff;

    &:active,
    &:first-child:active,
    &:not(.btn-check):active {
      background-color: $primary;
      border: 0;
      color: #fff;
    }
  }

  &.btn_outline_primary {
    background-color: #fff;
    border: 1px solid $primary;
    color: $primary;

    &:active,
    &:first-child:active,
    &:not(.btn-check):active {
      background-color: #fff;
      border: 1px solid $primary;
      color: $primary;
    }
  }

  &.btn_secondary {
    background-color: $gray;
    border: 0;
    color: $dark;

    &:active,
    &:first-child:active,
    &:not(.btn-check):active {
      background-color: $gray;
      border: 0;
      color: $dark;
    }
  }

  &.btn_dashed {
    background-color: $light-blue;
    border: 2px dashed $blue;
    color: $blue;

    &:active,
    &:first-child:active,
    &:not(.btn-check):active {
      background-color: $light-blue;
      border: 2px dashed $blue;
      color: $blue;
    }
  }

  &.btn_link {
    border: 0;
    font-size: inherit;
    padding: unset;
    line-height: unset;
    background-color: transparent;
    color: $primary;
    text-decoration: underline;
  }

  &:disabled:not(.btn_link) {
    background-color: $light-primary;
  }

  &.btn_link:disabled {
    color: $white-primary;
  }
}
