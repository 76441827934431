@import 'variables';

.refer_to_friends_container {
  .referral_item {
    padding-inline-start: 40px;
    position: relative;
    padding-block-start: 8px;
    padding-block-end: 20px;

    .point {
      position: absolute;
      inset-inline-start: 7px;
      inset-block-start: -5px;
      height: 0px;
      font-size: 24px;
      font-weight: bold;
      color: #1e1e1e;
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      inset-inline-start: 0;
      inset-block-start: 0;
      background-color: $primary;
      width: 20px;
    }

    &:after {
      content: '';
      position: absolute;
      inset-inline-start: 0;
      inset-block-start: 0;
      background-color: $gray-100;
      height: 100%;
      width: 20px;
      border-radius: 20px;
      z-index: 1;
    }

    &.active {
      .point {
        color: #fff;
      }
    }

    &.active:after {
      background-color: $primary;
    }

    &.bg_gray_100:before {
      background-color: $gray-100;
    }

    &.border_bottom_0:after {
      border-end-start-radius: 0;
      border-end-end-radius: 0;
    }

    &:first-of-type {
      padding-top: 20px;

      .point {
        inset-block-start: 4px;
      }
    }

    &:first-of-type:before {
      border-start-start-radius: 20px;
      border-start-end-radius: 20px;
    }

    &:last-of-type:before {
      border-end-start-radius: 20px;
      border-end-end-radius: 20px;
    }

    .title {
      font-weight: 700;
    }
  }
}
