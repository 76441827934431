.report_card {
  position: relative;
  width: 100%;
  padding-block: 16px;
  padding-inline: 12px;
  border-radius: 11px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;

  &.primary {
    background-color: rgba(56, 120, 226, 0.05);
  }

  &.secondary {
    background-color: #f8f8f8;
  }

  .report_item {
    padding: 10px;

    .value {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .actions {
    position: absolute;
    inset-inline-start: 50%;
    bottom: -40px;
    background-color: #fff;
    border-radius: 11px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    transform: translateX(-50%);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);

    &.view_mode {
      inset-inline-start: 95%;
      transform: translateX(-95%);
    }

    .btn {
      display: flex;
      padding: 12px;
      border: 0;
      border-radius: 11px;
      outline: none;

      &.btn_edit,
      &.btn_view {
        background-color: #f7fafe;
      }

      &.btn_scan {
        background-color: #fff4ef;
      }
    }

    .ellipsis {
      display: flex;
    }
  }
}
