@import 'variables';

.mobile_control_container {
  .react-tel-input {
    .special-label {
      color: $blue;
      font-weight: normal;
      font-family: 'Avenir Next LT Pro';
    }

    .form-control {
      padding-block: 14px;
      padding-inline-start: 64px;
      border-color: $border-color;
      border-radius: $border-radius;
      width: 100%;

      &:focus {
        border-color: $blue;
        box-shadow: unset;
      }
    }

    .flag-dropdown {
      .country-list {
        text-align: start;

        .search {
          .search-box {
            margin: 0;
            width: 100%;
            border-radius: $border-radius;
            border-color: $border-color;
            transition: 0.2s;

            &:focus {
              border-color: $blue;
            }
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 25px;
        inset-block-end: 14px;
        inset-inline-end: -5px;
        border-inline-end: 1px solid $border-color;
      }

      &::after {
        content: '';
        position: absolute;
        height: 31px;
        inset-block-end: 10px;
        background-color: rgba(255, 255, 255, 0.7);
      }

      &.one::after {
        width: 21px;
        inset-inline-end: -30.5px;
      }

      &.two::after {
        width: 29px;
        inset-inline-end: -41.5px;
      }

      &.three::after {
        width: 35px;
        inset-inline-end: -48.5px;
      }

      &.four::after {
        width: 46px;
        inset-inline-end: -58.5px;
      }
    }
  }

  .error_message {
    display: none;
    color: $light-primary;
  }

  &.danger {
    .react-tel-input {
      .form-control {
        border-color: $light-primary;
      }
    }

    .error_message {
      display: flex;
      align-items: center;
      margin-top: 6px;

      img {
        margin-inline-end: 8px;
      }
    }
  }
}
