@import 'variables';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 16px;

  .left_side {
    display: flex;

    .profile_image {
      border-radius: 50%;
      cursor: pointer;
    }

    .profile_info {
      padding-inline-start: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        font-weight: bold;
      }
    }
  }

  .right_side {
    display: flex;
    align-items: center;

    .gift_link {
      margin-inline-end: 12px;
    }

    .qr_link {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $gray;
    }
  }
}
