@import 'variables';

.stepper {
  padding-block: 16px;

  .progress_bar_container {
    width: 100%;
    height: 4px;
    border-radius: $border-radius;
    background-color: $gray-100;
    margin-block: 24px;

    .progress_bar {
      height: 100%;
      border-radius: $border-radius;
      background-color: $primary;
    }
  }

  .title_container {
    text-align: center;
    color: $dark;
    margin-block-end: 24px;

    .title {
      font-size: 25px;
      font-weight: bold;
    }
  }

  .button {
    border: 0;

    &.primary {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $primary;
      margin-block-start: 32px;
    }

    &.secondary {
      width: fit-content;
      background-color: $gray;
      color: $dark;
      padding-block: 12px;
      padding-inline: 16px;
      border-radius: $border-radius;
    }

    &:disabled {
      border-radius: 15px;
      background-color: $light-primary;
      color: $white-primary;
      font-weight: bold;
      font-size: 18px;
      padding: 16px;
      line-height: 24.59px;
      width: unset;
      height: unset;
    }
  }
}
