@import 'variables';

.product_card {
  position: relative;
  background-color: #f6f6f6;
  border-radius: 15px;

  .img {
    object-fit: contain;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-inline: 12px;
    padding-top: 12px;
    padding-bottom: 36px;

    .name {
      font-size: 16px;
      font-weight: 600;
      color: $dark;
    }

    .price {
      font-size: 18px;
      font-weight: bold;
      color: $primary;
    }
  }

  .increment_decrement_container {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%);
  }
}
