@import 'variables';

.increment_decrement_container {
  background-color: $white-primary;
  border-radius: $border-radius;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 125px;

  .button {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #e8bfbf;
    color: $primary;
    border-radius: $border-radius;
    border: 0;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.4s;

    &:disabled {
      background-color: rgba(232, 191, 191, 0.2);
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .path {
        stroke: $primary;
      }
    }
  }

  .label {
    color: $primary;
    font-size: 16px;
    font-weight: bold;
  }

  &.is_disabled {
    background-color: $gray-200;

    .button {
      &:disabled {
        background-color: $gray-400;

        .icon {
          .path {
            stroke: $gray-500;
          }
        }
      }
    }

    .label {
      color: $gray-500;
    }
  }
}
