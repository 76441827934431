@import 'variables';

.main {
  .title {
    font-weight: 600;
    color: $dark;
  }

  .email {
    font-weight: bold;
    color: $blue;
  }
}
