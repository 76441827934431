@import 'variables';

.label {
  width: 100%;
  position: relative;
  display: inline-block;

  .span {
    height: 0;
    padding-block: 10px;
    padding-inline: 16px;
    pointer-events: none;
    position: absolute;
    top: -6px;
    left: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 1;
    transform: scale(0.75) translateY(-70%) translateX(0px);
    background-color: #fff;
    color: $blue;
  }

  .select {
    padding: 14px;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    outline: none;
    background-clip: padding-box;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 1em;

    &:focus {
      border-color: $blue;
    }
  }
}
