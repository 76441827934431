@import 'variables';

.not_found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 48px;
  padding-inline: 12px;
  text-align: center;

  .title {
    color: $primary;
    font-weight: bolder;
    margin-top: 12px;
  }

  .text {
    font-weight: bolder;
  }

  .link {
    color: $blue;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
      color: $primary;
    }
  }
}
