.step5_container {
  min-height: 67vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 25px;
    font-weight: bold;
  }

  .paragraph {
    max-width: 360px;
  }
}
