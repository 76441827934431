@import 'variables';

.subscription_container {
  position: relative;
  border: 2px solid $gray-400;
  border-radius: 15px;
  padding-inline: 16px;
  padding-block-start: 16px;
  padding-block-end: 40px;
  height: 100%;
  text-align: start;
  transition: 0.4s;
  cursor: pointer;

  .discount {
    padding-block: 6px;
    padding-inline: 18px;
    border-radius: 50px;
    background-color: $gray-400;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.4s;
  }

  .icon_container {
    width: 42px;
    height: 42px;
    background-color: $gray-300;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    margin-bottom: 18px;
    transition: 0.4s;

    .icon {
      .path {
        fill: #4b5563;
        transition: 0.4s;
      }
    }

    &.basic {
      .family_icon {
        display: none;
      }
    }

    &.family {
      .user_icon {
        display: none;
      }
    }
  }

  .title {
    font-weight: bold;
    margin-bottom: 0;
  }

  .monthly_price_container {
    font-size: 18px;

    .monthly_price {
      font-size: 48px;
      font-weight: bold;
    }
  }

  .total_price {
    font-size: 18px;
    font-weight: bold;
  }

  .increment_decrement_container {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%);
  }

  &.selected {
    border-color: $primary;
    cursor: unset;

    .discount {
      background-color: $light-primary;
    }

    .icon_container {
      background-color: $white-primary;

      .icon {
        .path {
          fill: $primary;
        }
      }
    }
  }

  &.horizontal {
    padding: 20px;

    .icon_container {
      width: 125px;
      height: 125px;
      margin-bottom: 0;
    }
  }
}
